<template lang="pug">
  AppLayout
    Band.add-edit-content(contained, centered)

      div(v-show="loading", class="flex items-center space-x-2 text-lg")
        Icon(icon="spinner-third", prefix="fad", spin)
        span(class="text-supplement") Loading...

      Row(xl="row", class="xl:flex-row-reverse", v-if="!loading")

        Column(class="xl:w-1/3")
          div(class="add-edit-content__preview sticky")

            Card(v-if="mode === 'edit'")
              dl.dl-horizontal.dl-plain.dl-no-padding
                dt.flex.items-center.pt-0 Status
                dd.pt-0: ContentStatusSwitcher(:id="data.id", :status="data.status", @change="onContentStatusChange")
                dt.pb-0 Author
                dd.pb-0
                  router-link(:to="{name: 'users.view', params: { id: data.created_by.id }}", v-if="data.created_by") {{ data.created_by.full_name }}
                  span.text-supplement(v-else) -

            Card(title="Preview")
              div.content.has-type.type-video.has-languages.language-universal.has-destinations.destination-polynesia-hawaii-big-island
                h3.text-lg
                  a.content__link(:href="data.content_url", target="_blank", v-html="$markdown(data.title)")
                div.mt-3.space-x-2
                  span.font-bold {{ data.lead_in }}
                  span(v-html="$markdown(data.synopsis)")
                  em(v-show="(data.type_id === 1 || data.type_id === 2) && data.author") By {{ data.author }}
                  em(v-show="(data.type_id === 4 || data.type_id === 8) && data.runtime") Runtime: {{ data.runtime }} min.
                //div.content__video-wrapper.vimeo
                  iframe(
                    src="https://player.vimeo.com/video/127335923?title=0&amp;byline=0&amp;portrait=0&amp;player_id=xT7uxwPknjZy2prV",
                    id="xT7uxwPknjZy2prV",
                    frameborder="0",
                    webkitallowfullscreen="",
                    mozallowfullscreen="",
                    allowfullscreen="",
                    data-embed-type="vimeo"
                  )

        Column(class="xl:w-2/3")
          Card.add-edit-content__form(:title="title")
            template(v-slot:buttons, v-if="mode === 'edit'")
              Button(type="danger", prepend-icon="trash", icon-prefix="fas", @click="deleteContent") Delete

            div(class="step step--first border-l ml-12 pl-12 pr-12 py-6 relative md:ml-16 md:pl-16 md:pr-20 md:py-10")
              h1 Enter URL
              div.step__content(class="mt-6")
                Form.form--1(endpoint="blocks/content/parse-url", :data="data", @error="onParseUrlError", @success="onParseUrlSuccess")
                  InputGroup(name="content_url")
                    TextInput(prepend-icon="browser", v-model="data.content_url", auto-focus, :disabled="url.parsing", @input="onUrlChange", ref="url")
                    Alert.alert--custom(:type="url.status", :content="url.message", v-show="url.status && url.message")
                  template(v-slot:footer)
                    div.mt-6.text-right.w-full(v-show="!url.parsing && !url.parsed")
                      Button(type="primary", append-icon="long-arrow-right", @click="onParseUrl()", submit) Next

            Form.form--2(:method="method", :endpoint="endpoint", :data="data", @success="onAddContentSuccess", ref="form")
              div(class="step border-l ml-12 pl-12 pr-12 py-6 relative md:ml-16 md:pl-16 md:pr-20 md:py-10")
                h1 The basics
                div.step__content(v-if="url.parsed", class="mt-6")

                  InputGroup(name="title", label="Title")
                    TextInput(v-model="data.title", textarea, :rows="1")

                  MarkdownInput(v-model="data.synopsis", label="Synopsis", name="synopsis", :suggested-min-length="10", :suggested-max-length="350")

                  InputGroup(name="type_id", label="Media Type")
                    Radios(v-model="data.type_id")
                      div(class="gap-2 grid grid-cols-2 md:grid-cols-4")
                        Radio.media-types-item(v-for="mediaType in options.mediaTypes", :key="mediaType.id", :value="mediaType.id", :class="{'is-selected': data.type_id === mediaType.id}")
                          span.media-types-item__icon(v-if="mediaType.icon"): Icon(:icon="mediaType.icon")
                          span {{ mediaType.name }}

              div(class="step border-l ml-12 pl-12 pr-12 py-6 relative md:ml-16 md:pl-16 md:pr-20 md:py-10")
                h1 Just a few more things
                div.step__content(v-if="url.parsed", class="mt-6")

                  InputGroup(name="tag_ids", label="Subject Area(s)", description="Click the chevron or your down arrow key to see list")
                    MultiSelect(v-model="data.tag_ids", placeholder="Enter subject area(s)...", endpoint="options/tags", :spellcheck="false")

                  InputGroup(name="destination_ids", label="Destination(s)", description="Click the chevron or your down arrow key to see list")
                    MultiSelect(v-model="data.destination_ids", placeholder="Enter destination(s)...", endpoint="options/destinations", option-label="label", :spellcheck="false")

                  InputGroup(name="depth", label="Depth")
                    Radios(v-model="data.depth_id")
                      div(class="gap-2 grid sm:grid-cols-3")
                        Radio.content-depths-item(v-for="(depth, i) in options.depths", :key="depth.id", :value="depth.id", :class="{'is-selected': data.depth_id === depth.id}")
                          span.content-depths-item__icon: Icon(:icon="depth.icon")
                          span {{ depth.name }}

                  div(v-show="data.type_id === 1 || data.type_id === 2 || data.type_id === 4 || data.type_id === 8", class="space-y-8 sm:flex sm:space-x-8 sm:space-y-0")
                    div(v-if="data.type_id === 1 || data.type_id === 2", class="sm:w-1/2")
                      InputGroup(name="author", label="Author")
                        TextInput(v-model="data.author")
                    div(v-if="data.type_id === 4 || data.type_id === 8", class="sm:w-1/2")
                      InputGroup(name="runtime", label="Runtime")
                        NumberInput(v-model="data.runtime", placeholder="Round to the nearest minute...")

              div(class="step border-l ml-12 pl-12 pr-12 py-6 relative md:ml-16 md:pl-16 md:pr-20 md:py-10", :class="{'step--last': !url.parsed}")
                h1 Finally, anything else you'd like to do?
                div.step__content(v-if="url.parsed", class="mt-6")

                  InputGroup(name="collection_ids", label="Collection(s) - optional", description="Click the chevron or your down arrow key to see list")
                    MultiSelect(v-model="data.collection_ids", placeholder="Add this to a collection...", endpoint="options/all/collections", option-label="label")

                  InputGroup(label="Image - optional", name="featured_image", description="Max upload size: 10MB")
                    ImageInput(v-model="data.featured_image")

                  InputGroup(name="expires_at", label="Expiration Date - optional")
                    DatePicker(v-model="data.expires_at", placeholder="This content will not expire. To set an expiration, select a date", :presets="expiresAtPresets")

              template(v-slot:footer)
                div(class="border-l flex flex-grow justify-end ml-12 pb-12 pl-12 pr-12 md:ml-16 md:pb-16 md:pl-16 md:pr-20", v-show="url.parsed")
                  Button(type="primary", submit) {{ mode === 'edit' ? 'UPDATE' : 'SAVE' }}
</template>

<script>
import { add as dateAdd } from "date-fns"
import { debounce, each, get, isEmpty } from "lodash-es"
import { ContentStatusSwitcher } from "@syntax51/ag-shared-assets"
import MarkdownInput from "../../components/Form/MarkdownInput.vue"

export default {
  metaInfo() {
    return { title: this.title }
  },
  components: {
    ContentStatusSwitcher,
    MarkdownInput
  },
  data: () => ({
    loading: true,
    url: {
      status: null,
      message: "",
      parsing: false,
      parsed: false
    },
    options: {
      depths: [],
      mediaTypes: []
    },
    expiresAtPresets: [
      { label: "1 month", date: dateAdd(new Date(), { months: 1 }) },
      { label: "6 months", date: dateAdd(new Date(), { months: 6 }) },
      { label: "1 year", date: dateAdd(new Date(), { years: 1 }) },
      { label: "3 years", date: dateAdd(new Date(), { years: 3 }) },
      { label: "5 years", date: dateAdd(new Date(), { years: 5 }) }
    ],
    data: {
      block_type: "content",
      content_url: "",
      title: "",
      type_id: 2,
      featured_image: null,
      lead_in: "",
      synopsis: "",
      tag_ids: [],
      depth_id: 2,
      destination_ids: [],
      author: "",
      runtime: null,
      collection_ids: [],
      expires_at: ""
    }
  }),
  computed: {
    mode() {
      return this.$route.name === "content.edit" ? "edit" : "add"
    },
    title() {
      return (this.mode === "edit" ? "Edit" : "Add") + " Content"
    },
    method() {
      return this.mode === "edit" ? "PUT" : "POST"
    },
    endpoint() {
      return this.mode === "edit" ? `blocks/content/${this.$route.params.id}/update` : "blocks/content"
    },
    synopsisFeedback() {
      let status = "info",
        content = "We recommend fewer than 350 characters.",
        length = this.data.synopsis && this.data.synopsis.length

      if (length >= 10 && length <= 350) {
        status = "success"
        content = `This synopsis is ${length} characters.`
      } else if (length > 350) {
        status = "danger"
        content = `This synopsis is **${length}** characters long; we recommend fewer than 350.`
      }

      return { status, content }
    }
  },
  async mounted() {
    let depthOptions = await this.$api.get("blocks/content/depths")
    let mediaTypeOptions = await this.$api.get("blocks/content/media-types")
    this.options.depths = get(depthOptions, "data.items", [])
    this.options.mediaTypes = get(mediaTypeOptions, "data.items", [])

    if (this.mode === "add") {
      this.data.published_at = new Date()
    } else if (this.mode === "edit") {
      await this.fetch()
    }

    this.loading = false
  },
  methods: {
    async fetch() {
      if (this.mode === "add") return

      this.loading = true
      let response = await this.$api.get(this.endpoint)
      this.data = get(response, "data.item", {})
      this.url.parsed = true
      this.loading = false
    },
    onParseUrl() {
      this.url.status = "loading"
      this.url.message = "Hold tight! We're fetching data to auto-populate for you."
      this.url.parsing = true
    },
    onParseUrlError(error) {
      if (isEmpty(error)) return
      this.url.status = null
      this.url.message = ""
      this.url.parsing = false

      setTimeout(() => {
        this.$refs.url.focus()
      }, 1)
    },
    onParseUrlSuccess(data) {
      each(data, async (value, key) => {
        if (key === "image") {
          this.data.featured_image = value
          this.data.featured_image_url = value
        } else {
          this.data[key] = value
        }
      })

      let autoPopulated = []
      let dataKeys = Object.keys(data)
      if (dataKeys.indexOf("title") !== -1) autoPopulated.push("title")
      if (dataKeys.indexOf("synopsis") !== -1) autoPopulated.push("synopsis")
      if (dataKeys.indexOf("type_id") !== -1) autoPopulated.push("media type")
      if (dataKeys.indexOf("image") !== -1) autoPopulated.push("image")

      if (autoPopulated.length) {
        if (autoPopulated.length === 1) {
          this.url.message = `We auto-populated **${autoPopulated[0]}**.`
        } else {
          let lastAutoPoplated = autoPopulated.pop()
          this.url.message = `We auto-populated **` + autoPopulated.join("**, **") + `** and **${lastAutoPoplated}**.`
        }

        this.url.status = "success"
        this.url.message += ` Please review and edit as necessary.`
      } else {
        this.url.status = null
        this.url.message = ""
      }

      this.url.parsing = false
      this.url.parsed = true
    },
    onAddContentSuccess(response) {
      this.$notification.success("Got it!")

      if (this.mode === "add") {
        this.data.content_url = ""
        this.resetForm()

        setTimeout(this.$refs.url.focus(), 1)
      } else if (this.mode === "edit") {
        this.data.status = response.item.status
      }
    },
    onUrlChange() {
      if (this.mode === "add") {
        this.resetForm()
      } else if (this.mode === "edit") {
        this.checkUrl()
      }
    },
    checkUrl: debounce(async function () {
      this.url.parsing = true
      this.url.parsed = false

      if (this.data.content_url) {
        let response = await this.$api.post(`blocks/content/parse-url`, {
          content_url: this.data.content_url,
          id: this.data.id
        })

        this.data.lead_in = response.data.lead_in

        if (!this.data.featured_image && response.data.image) {
          this.data.featured_image = response.data.image
        }

        this.url.parsed = true
      }

      this.url.parsing = false
    }, 500),
    resetForm() {
      this.$refs.form.clearErrors()
      this.url = { status: null, message: "", parsing: false, parsed: false }

      this.data.title = ""
      this.data.lead_in = ""
      this.data.synopsis = ""
      this.data.type_id = 2
      this.data.tag_ids = []
      this.data.destination_ids = []
      this.data.depth_id = 2
      this.data.author = ""
      this.data.runtime = null
      this.data.collection_ids = []
      this.data.featured_image = null
      this.data.expires_at = ""
    },
    onContentStatusChange(response) {
      let { published_at, expires_at, status } = response.data
      this.data.published_at = published_at
      this.data.expires_at = expires_at
      this.data.status = status
    },
    async deleteContent() {
      if (
        await this.$modal.confirm({
          type: "danger",
          title: "Are you sure?",
          message: `Are you sure you want to delete this content? This cannot be undone.`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`blocks/${this.data.id}`)) {
          this.$notification.success(`Content successfully deleted.`)
          this.$router.push({ name: "content" })
        }
      }
    }
  }
}
</script>

<style>
.add-edit-content {
  counter-reset: content-step;

  &__preview {
    top: calc(theme("header.height") + theme("spacing.16"));
  }

  &__form .card-body-inner {
    @apply p-0;
  }

  .step {
    counter-increment: section;

    &:before {
      @apply absolute left-0 flex h-12 w-12 items-center justify-center rounded-full border bg-white font-semibold text-supplement;
      content: counter(section);
      margin-left: calc(0px - theme("spacing.6"));
      margin-top: -2px;
    }

    &--first {
      @apply pt-12;
      @apply md:pt-16;
      @apply lg:pt-20;
    }

    &--last {
      @apply pb-12;
      @apply md:pb-16;
      @apply lg:pb-20;
    }

    &__content {
      *:last-child {
        @apply mb-0;
      }
    }
  }

  .alert--custom.alert {
    @apply mt-4 border-none p-0;

    .alert-icon {
      @apply mr-3 h-6 w-8 rounded-full text-xs;
      min-width: auto;
    }
  }

  .content-depths-item,
  .media-types-item {
    @apply ml-0 !important;
    @apply p-0;

    &__icon {
      @apply mr-2 text-lg text-primary-default;
      line-height: 1;
    }

    .form-label {
      @apply flex items-center justify-center rounded border border-gray-400 p-3;
      height: 45px;

      &:hover {
        @apply bg-primary-50;
      }
    }

    .form-check-content {
      @apply ml-0 flex items-center;
    }

    .form-check-custom-input {
      @apply hidden;
    }

    &.is-selected .form-label {
      @apply border-primary-default bg-primary-50 text-primary-default;
    }
  }

  .form--1 {
    .form-inputgroup {
      @apply mb-0;
    }

    .form-feedback {
      @apply hidden;
    }
  }

  .form--2 .form-feedback {
    @apply relative ml-12 mb-0 border-l py-6 pl-12 pr-12 md:ml-16 md:pl-16 md:pr-20 md:pb-10;
  }

  .form__footer {
    @apply bg-white p-0;
  }

  .formatting-help .dropdown-menu {
    @apply max-w-full py-0;
    min-width: 250px;

    @screen md {
      min-width: 400px;
    }
  }
}
</style>
